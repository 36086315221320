import * as React from 'react'
import { PhoneNumberField } from '../../Fields/CustomTextField'
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from '../../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { formatPhoneNumber } from '../../utils/string-utils'
import { NuModal } from '../../Modal'
import { newBackendClient } from '../../clients/new-backend.client'
import gql from 'graphql-tag'
import { Mutation, MutationCreatePromoUserArgs } from '../../../generated/nest-graphql'
import queryString from 'query-string'
import { useState } from 'react'

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Required')
    .min(7, 'Phone number is not valid')
    .matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      'Phone number is not valid'
    )
})
const PromoModalContent: React.FC<{
  buttonText: string
  buttonHelpText: string
  postSubmissionText1: string
  postSubmissionText2: string
  promoContent: string
}> = ({ buttonText, buttonHelpText, postSubmissionText1, postSubmissionText2, promoContent }) => {
  const [successfulSubmit, setSuccessfulSubmit] = useState(false)
  const queryParams = queryString.parse(window.location.search)
  async function onSubmit(values) {
    const formattedPhone = values?.phoneNumber?.replace(/-/g, '')
    await newBackendClient.mutate<Mutation, MutationCreatePromoUserArgs>({
      variables: {
        promoUserInput: {
          phoneNumber: formattedPhone,
          promoContent,
          queryParams: {...queryParams, currentPagePathname: window.location.pathname }
        },
      },
      mutation: gql`
        mutation createPromoUser($promoUserInput: PromoUserInput!) {
          createPromoUser(promoUserInput: $promoUserInput) {
            phoneNumber
            promoContent
          }
        }
      `,
    })
    setSuccessfulSubmit(true)
  }

  return (
    <div>
      <Formik
        initialValues={{ phoneNumber: '' }}
        enableReinitialize={false}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            {successfulSubmit ? (
              <div className="border-2 border-black bg-white text-center italic p-1">
                <p>
                  {postSubmissionText1}
                  <br />
                  {formatPhoneNumber(values.phoneNumber)}.
                </p>
                <p>{postSubmissionText2}</p>
              </div>
            ) : (
              <>
                <Field name={'phoneNumber'} component={PhoneNumberField} required={true}/>
                <Button className="w-full py-3" disabled={isSubmitting} type={'submit'} id={'submit-btn'}>
                  {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : buttonText}
                </Button>
                <p className="text-center pt-2">{buttonHelpText}</p>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export const PromoModal = ({
  header,
  subHeader,
  promoContent = '',
  buttonText,
  buttonHelpText,
  postSubmissionText1,
  postSubmissionText2,
  delayMS,
  modalEnabled = true,
}) => {
  const modalStyle = {
    content: {
      maxWidth: `360px`,
      flexDirection: 'column' as any,
    },
    overlay: {
      backgroundColor: `rgba(0, 0, 0, 0.75)`,
    },
  }
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const toggleModal = React.useCallback(() => {
    setIsOpen((open) => !open)
  }, [setIsOpen])

  function closeModal() {
    setIsOpen(false)
  }

  React.useEffect(() => {
    const modalTimeout = setTimeout(() => {
      toggleModal()
    }, delayMS)

    return () => clearTimeout(modalTimeout)
  }, [])
  if (!modalEnabled) return null
  return (
    <NuModal
      preventScroll={true}
      contentLabel={header}
      toggleModal={toggleModal}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={true}
      modalStyle={modalStyle}
    >
      <div className="px-2 lg:px-4">
        <p className="w-full px-2 lg:px-0 mx-auto text-nugray text-center mt-0 mb-1 leading-tight">{subHeader}</p>
        <PromoModalContent
          buttonText={buttonText}
          buttonHelpText={buttonHelpText}
          postSubmissionText1={postSubmissionText1}
          postSubmissionText2={postSubmissionText2}
          promoContent={promoContent}
        />
      </div>
    </NuModal>
  )
}
